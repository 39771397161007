<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { Fancybox, Carousel } from 'fancyapps-ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '@fancyapps/ui/dist/carousel/carousel.css';
import '@fancyapps/ui/dist/carousel/carousel.thumbs.css';

defineProps({
    options: {
        type: Object,
        default: () => ({}),
    },
});

const container = ref(null);
const carouselInitialized = ref(false);

onMounted(() => {
    new Carousel('.f-carousel', {
        infinite: true,
        slidesPerPage: 1,
        arrows: false,
        dots: false,
        center: true,
        draggable: true,
        touch: true,
        responsive: {
            768: {
                slidesPerPage: 2,
            },
            1024: {
                slidesPerPage: 3,
            },
        },
        on: {
            init: () => (carouselInitialized.value = true),
        },
    });
});

watchEffect(() => {
    Fancybox.unbind(container.value);
    Fancybox.close();

    Fancybox.bind(container.value, '[data-fancybox]', {});
});
</script>

<template>
    <div ref="container">
        <slot></slot>
    </div>
</template>

<style>
.fancybox__container {
    z-index: 9999;
}
.f-carousel__dots {
    bottom: 0;
}
.f-carousel__nav .f-button {
    display: none;
}
.f-carousel__dot {
    background-color: white;
}
</style>
